 .icon {
     cursor: pointer;
 }

 .icon-delete {
     color: #f95f3c;
 }

 .icon-delete:hover {
     transition: color 0.8s;
     color: #921d02;
 }

 .icon-edit {
     color: #3c9bf9;
 }

 .icon-edit:hover {
     transition: color 0.8s;
     color: #0D3C6B;
 }