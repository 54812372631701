@import "./variables.scss";

@mixin button-common() {
color: #fff !important;
border-radius: 6px !important;
padding: 12px !important;
}

.gradient-button{
background: linear-gradient(45deg, #3c9bf9 20%, #0D3C6B 90%);
@include button-common();
}


.gradient-button:hover {
background: linear-gradient(45deg, #3c9bf9 40%, #0D3C6B 100%);
transition: box-shadow 0.3s;
box-shadow: rgba(136, 165, 191, 0.8) 0px 13px 27px -5px, rgba(97, 169, 232, 0.8) 0px 8px 16px -8px;
}

.secondary-button{
background: none;
border:1px solid !important;
border-image-slice: 1 !important;
border-width: 2px !important;
border-image-source: linear-gradient(to left, #0D3C6B, #3c9bf9)!important;
@include button-common();

color:$primary-color !important;
}

.secondary-button:hover {
border: 1px solid linear-gradient(45deg, #3c9bf9 40%, #0D3C6B 100%);
box-shadow: rgba(136, 165, 191, 0.8) 0px 13px 27px -5px, rgba(97, 169, 232, 0.8) 0px 8px 16px -8px;
}

.delete-button{
background: linear-gradient(45deg, #f83e14 20%, #921d02 90%);
@include button-common();
}

.delete-button:hover {
background: linear-gradient(45deg, #f95f3c 40%, #921d02 100%);
box-shadow: rgba(247, 134, 47, 0.8) 0px 13px 27px -5px, rgba(207, 63, 47, 0.8) 0px 8px 16px -8px;
}