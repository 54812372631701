@import "./variables.scss";

.categories{
  margin-left: 50px;
  margin-right: 50px;

  .category-name{
    color:$primary-color;
    font-size:16px;
  }
  
 
}