.button{
    color:#0D3C6B;
    text-decoration: none;
    font-weight: bold;
    padding: 5px;
    font-size: large;

}

.button:hover{
    color:#62A8EE;
    border-bottom: 2px solid #0D3C6B;
    transition: color 0.5s;
        /* animation-name: example;
        animation-duration: 4s;
        animation-iteration-count: infinite; */
}

/* @keyframes example {
    0% {
        border: 1px solid #0D3C6B;
        padding:6px;
    }

    25% {
        border: 1px solid #b3bbc1;
            padding:7px;
    }

    
    50% {
    border: 1px solid #0D3C6B;
        padding:10px;
    }

    75% {
        border: 1px dashed #0D3C6B;
        left: 0px;
        top: 200px;
    }

    100% {
        border: 1px dashed #0D3C6B;
        left: 0px;
        top: 0px;
    }
} */